@import "../_config/index";

@font-face {
  font-family: "SoinSans";
  src: url(font("soin-sans/SoinSans-Thin.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-Thin.woff")) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans";
  src: url(font("soin-sans/SoinSans-Light.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-Light.woff")) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans";
  src: url(font("soin-sans/SoinSans-Bold.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans-Roman";
  src: url(font("soin-sans/SoinSans-Roman.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-Roman.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans-Oblique";
  src: url(font("soin-sans/SoinSans-ThinOblique.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-ThinOblique.woff")) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans-Oblique";
  src: url(font("soin-sans/SoinSans-LightOblique.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-LightOblique.woff")) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans-Oblique";
  src: url(font("soin-sans/SoinSans-Oblique.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-Oblique.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SoinSans-Oblique";
  src: url(font("soin-sans/SoinSans-BoldOblique.woff2")) format("woff2"),
    url(font("soin-sans/SoinSans-BoldOblique.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
